import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form, ListGroup, ListGroupItem } from 'shards-react';
import { FormInputWithDefault } from '../layout/FormInputWithDefault';

import API from '../../service/Api';
import Alerts from '../../service/Alerts';

import './Dashboard.css';


class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      apiKeys: [],
      newKey: null,
    };
  }

  componentDidMount() {
    this.loadSettings()
  }

  loadSettings() {
    this.setState({ isLoading: true });
    API.call('GET', '/v0/settings')
      .then(body => this.setState({ apiKeys: body.api_keys || [] }))
      .finally(() => this.setState({ isLoading: false }));
  }

  saveNewKey() {
    const body = { name: document.getElementById('api-key-name').value }
    Alerts.showAlert('Saving...');
    API.call('POST', '/v0/settings/apikeys', body)
      .then(this.onCreatedNewKey.bind(this))
      .catch(err => Alerts.showAlert(`Error saving API key! ${err}`, 'warning'));
  }

  onCreatedNewKey(body) {
    Alerts.showAlert('Added new key.');
    document.getElementById('api-key-name').value = 'my api key';
    const { name, key } = body;
    this.setState({ newKey: { name, key } });
    this.loadSettings();
  }

  deleteKey(key) {
    Alerts.showAlert('Deleting...');
    API.call('DELETE', `/v0/settings/apikeys/${key.id}`)
      .then(() => Alerts.showAlert(`Deleted key '${key.name}.`))
      .catch(err => Alerts.showAlert(`Error deleting API key! ${err}`, 'warning'))
      .finally(() => this.loadSettings())
  }

  render() {
    return <div className="page-content">
      <h1>Read-Later Settings</h1>
      { this.state.isLoading ? 'Loading...' : this.renderSettings() }
    </div>;
  }

  renderSettings() {
    return <>
      <div>
        <h3>API Keys</h3>
        {
          this.state.apiKeys.length > 0
            ? <ListGroup id="api-keys-list">{ this.state.apiKeys.map(key => this.renderApiKey(key)) }</ListGroup>
            : <p>No API Keys.</p>
        }
        <h3>Create New API Key</h3>
        <div id="new-api-key-box">
          <Form>
            <FormInputWithDefault
              placeholder="Key name"
              default={'my api key'}
              id="api-key-name"
            />
            <Button onClick={e => { e.preventDefault(); this.saveNewKey() }} type="submit">Save</Button>
          </Form>
        </div>
        {
          this.state.newKey && <div id="created-api-key-box">
            <p>Created key '{this.state.newKey.name}': {this.state.newKey.key}</p>
          </div>
        }
      </div>
    </>
  }

  renderApiKey(key) {
    return <ListGroupItem key={key.name}>
        {key.name}
        <Button outline theme="secondary" className="api-key-delete-btn" onClick={() => this.deleteKey(key) }>Delete</Button>
      </ListGroupItem>
  }

}


const stateToProps = state => ({ view: state.view });
export default connect(stateToProps)(Settings);